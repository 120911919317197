import React, { useEffect, useRef, useState } from "react";



const TextChange = (props) => {
  const [actualText, setActualText] = useState(props.initialText);
  const textNum = useRef(0);
  const maxText = props.text.length - 1;

  useEffect(() => {
    const interval = setInterval(() => {
      setActualText(props.text[textNum.current]);
      textNum.current += 1;

      if (textNum.current > maxText) {
        textNum.current = 0;
      }
    }, 2700);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h2 className=" l1-txt1 txt-center p-b-10">
        Creamos
      </h2>
      <h2 className=" txt-center l1-txt1 p-b-60">
        {actualText}
      </h2>
    </>
  );
};

export default TextChange;
