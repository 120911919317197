import React from 'react'
import TextChange from './TextChange';


export default function Datos() {

  let subTitle = [
    "Experiencias Digitales",
    "Realidades Aumentadas",
    "Realidades Virtuales",
    "Contenido para Nuevos Medios",
    "Contenido para Redes Sociales",
    "Arte y Tecnología",
  ];
  return (
    <>

        
    
    <div className='bg-g1 size1 flex-w flex-col-c-sb p-l-15 p-r-15 p-t-55 p-b-35 respon1 content'>
    <object
            type="image/svg+xml"
            data="images/logo-viuu.svg"
            className="logo"
          >
            svg-animation
          </object>
    <div className="flex-col-c p-t-50 p-b-50">
    
			
      <TextChange text={subTitle} initialText="Experiencias" /> 
	



			<div>
        
    </div>



		</div>


    <span className="s1-txt4 txt-center">
			 <a className="no-link" href='https://perromochofilms.com'>Producción Audiovisual - Perromocho Films</a>
		</span>
        <span className="s1-txt3 txt-center">
			Viuu.Tech | Experiencias de Arte y Tecnología ® 2022 | <a className="no-link" href='/datos'>Política de tratamiento de datos personales</a>
		</span>
		
	</div>
    </>

  )
}
